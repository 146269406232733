/*
 * Welcome to your app"s main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

require("../css/bootstrap.css");
require("../css/less/theme.less");

const $ = require("./imports/jquery.min");
global.$ = global.jQuery = $;

require("./imports/bootstrap");
require("./imports/flexslider.min");
require("./imports/lightbox.min");
require("./imports/masonry.min");
require("./imports/spectragram.min");
// require("./imports/ytplayer.min");
require("./imports/smooth-scroll.min");
require("./imports/instafeed.min");
require("./imports/parallax");

require("./imports/scripts");

const Cookies = require("js-cookie");

$(document).ready(function () {

    // cookie preferences
    const $cookieBanner = $("#cookie-banner");
    if (!!$cookieBanner[0]) {
        if (Cookies.get("accept_cookies") !== "true") {
            $cookieBanner.removeClass("hide");
        }

        $cookieBanner.on("click.cookies", "button", (e) => {
            Cookies.set("accept_cookies", true, { expires: 365 });
            $cookieBanner.addClass("hide");
            $(document).off(".cookies");
        });
    }
});
